/* src/index.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";



/* Apply a global box-sizing rule */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Basic body styling */
body {
  font-family: Arial, sans-serif; /* Set a default font */
  line-height: 1.6;               /* Improve readability */
  background-color: #f4f4f4;     /* Light background color */
  color: #333;                    /* Dark text color for contrast */
}

/* Remove default link styling */
a {
  text-decoration: none;
  color: inherit; /* Inherit color from parent element */
}

/* Set default styles for headers */
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

/* Style for containers */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Example of styling for buttons */
button {
  background-color: #007bff; /* Primary button color */
  color: #fff;               /* White text color */
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3; /* Darker button color on hover */
}
